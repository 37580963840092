import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { BeneficialOwner } from '../../../../types/instant-lease-api';
import FormLayout from '../../../common/form-layout/form-layout';
import InputField from '../../../common/input-field/input-field';
import TwoInputFields from '../../../common/two-input-fields/two-input-fields';

export interface BoFormValues {
  uuid?: string | undefined;
  person_uuid?: string | undefined;
  first_name: string;
  last_name: string;
  city: string;
  zip: string;
}

export interface BoSubmittedValues extends BoFormValues {
  modified: boolean;
}

interface BOModalProps {
  title: string;
  subtitle?: string;
  visibility: boolean;
  toggleVisibility?: () => void;
  toggleAdditionalModal?: () => void;
  onSubmit: (owner: BoSubmittedValues) => void;
  onClose?: () => void;
  owner?: BeneficialOwner | undefined;
}

const BOModal: React.FC<BOModalProps> = ({
  visibility,
  owner,
  onClose,
  toggleVisibility,
  onSubmit,
}) => {
  // Form validation
  const validationSchema = yup.object({
    uuid: yup.string(),
    person_uuid: yup.string(),
    first_name: yup.string().required('Pflichtfeld').min(2),
    last_name: yup.string().required('Pflichtfeld').min(2),
    city: yup
      .string()
      .matches(/^(|.{2,})$/, 'Pflichtfeld')
      .required('Pflichtfeld'),
    zip: yup
      .string()
      .test(
        'len',
        'Pflichtfeld',
        (val: any) =>
          val === undefined || val.length === 0 || val.match(/\b\d{5}\b/g),
      )
      .required('Pflichtfeld'),
  });

  // Form Hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<BoFormValues>({
    mode: 'onTouched',
    defaultValues: {},
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (owner) {
      setValue('first_name', owner?.person?.first_name || '');
      setValue('last_name', owner?.person?.last_name || '');
      setValue(
        'city',
        owner?.person?.contact_details?.addresses?.length &&
          owner?.person?.contact_details?.addresses[0].locality
          ? owner?.person?.contact_details?.addresses[0].locality
          : '',
      );
      setValue(
        'zip',
        owner?.person?.contact_details?.addresses?.length &&
          owner?.person?.contact_details?.addresses[0].postal_code
          ? owner?.person?.contact_details?.addresses[0].postal_code
          : '',
      );
      trigger();
    }
  }, [owner, setValue, trigger]);

  // Form submit handler
  const formSubmitHandler = (values: BoFormValues) => {
    if (owner) {
      onSubmit({
        uuid: owner?.uuid,
        person_uuid: owner?.person?.uuid,
        first_name: values.first_name,
        last_name: values.last_name,
        zip: values.zip,
        city: values.city,
        modified: true,
      });
    } else {
      onSubmit({
        uuid: uuidv4(),
        person_uuid: uuidv4(),
        first_name: values.first_name,
        last_name: values.last_name,
        zip: values.zip,
        city: values.city,
        modified: true,
      });
    }
    toggleVisibility && toggleVisibility();
  };

  return (
    <>
      {visibility && (
        <FormLayout sectionTitle='Personendaten' data-testid='bo-modal'>
          <form className='bo-modal' onSubmit={handleSubmit(formSubmitHandler)}>
            <div style={{ alignSelf: 'center' }}>
              <InputField
                {...register('first_name')}
                label='Vorname'
                name='first_name'
                error={errors.first_name?.message}
              />
              <InputField
                {...register('last_name')}
                label='Nachname'
                name='last_name'
                error={errors.last_name?.message}
              />

              <TwoInputFields
                label='Postleitzahl | Wohnort'
                gridTemplateColumns='25% 70%'
              >
                <InputField
                  {...register('zip')}
                  name='zip'
                  error={errors.zip?.message}
                />
                <InputField
                  {...register('city')}
                  name='city'
                  error={errors.city?.message}
                />
              </TwoInputFields>
            </div>
            <div className='add-additional-person'>
              <span
                role='presentation'
                onClick={() => {
                  if (onClose) onClose();
                }}
                className='button-text icon-text close-button'
              >
                Abbrechen
              </span>
              <div
                role='presentation'
                className='save-button button-text'
                onClick={handleSubmit(formSubmitHandler)}
                data-testid='bo-save'
              >
                Speichern
              </div>
            </div>
          </form>
        </FormLayout>
      )}
    </>
  );
};

export default BOModal;
