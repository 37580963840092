import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EDevice } from '../../../../redux/sequence/enums';
import { SequenceConfig } from '../../../../redux/sequence/types';
import { RootState } from '../../../../redux/types';
import { Partner, Type } from '../../../../types/instant-lease-api';
import { ReactComponent as CheckCircleIcon } from '../icons/check.svg';
import {
  ReactComponent as B2BPhaseFour,
  ReactComponent as B2CPhaseThree,
} from '../icons/phase-four.svg';
import {
  ReactComponent as B2BPhaseOne,
  ReactComponent as B2CPhaseOne,
} from '../icons/phase-one.svg';
import {
  ReactComponent as B2BPhaseThree,
  ReactComponent as B2CPhaseTwo,
} from '../icons/phase-three.svg';
import { ReactComponent as B2BPhaseTwo } from '../icons/phase-two.svg';
import './sequence-phases.css';

interface SequencesPhasesProps extends React.HTMLProps<HTMLDivElement> {
  config: SequenceConfig[];
  sequence: number;
  partner: Partner;
  step: number;
  type: Type.B2B | Type.B2C;
}

const SequencesPhases: React.FC<SequencesPhasesProps> = ({
  config,
  sequence,
  step,
  partner,
  type,
}) => {
  // sequence Storage
  const { device } = useSelector((state: RootState) => state.sequence);

  // Dispatch hook
  const dispatch = useDispatch();

  // Phases icons
  const getPhasesIcon = (index: number) => {
    if (type === Type.B2B) {
      switch (index) {
        case 0:
          return <B2BPhaseOne className='sequence-icon' />;
        case 1:
          return <B2BPhaseTwo className='sequence-icon' />;
        case 2:
          return <B2BPhaseThree className='sequence-icon' />;
        case 3:
          return <B2BPhaseFour className='sequence-icon' />;
        default:
          return <B2BPhaseOne className='sequence-icon' />;
      }
    }
    switch (index) {
      case 0:
        return <B2CPhaseOne className='sequence-icon' />;
      case 1:
        return <B2CPhaseTwo className='sequence-icon' />;
      case 2:
        return <B2CPhaseThree className='sequence-icon' />;
      default:
        return <B2CPhaseOne className='sequence-icon' />;
    }
  };

  // Scroll based on phase
  useEffect(() => {
    if (sequence !== 0 && device === EDevice.MOBILE) {
      const previousStep = document.getElementById(
        `step-${sequence === 0 ? 0 : sequence - 1}`,
      );

      previousStep?.scrollIntoView({
        behavior: 'instant' as any,
        block: 'center',
        inline: 'center',
      });

      const step = document.getElementById(`step-${sequence + 1}`);
      setTimeout(() => {
        step?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }, 2000);
    }
  }, [device, dispatch, sequence]);

  // Config based on leasing type
  const currentConfig =
    partner === Partner.ALD_FLEX && type !== Type.B2B
      ? config
      : config.splice(0, config.length - 1);

  return (
    <div className='justify-center'>
      <div id='sequence-steps' className='sequence-steps'>
        {currentConfig.map((seq, index) => {
          if (index === currentConfig.length - 1) return null;
          let style = '';
          if (sequence === index && step === 0) {
            style = 'active';
          } else if (sequence === index && step > 0) {
            style = 'active';
          } else if (sequence > index) {
            style = 'complete';
          }
          return (
            <div id={`step-${index}`} className={`single-step`} key={seq.key}>
              <div className={`sequence-container ${style}`}>
                <span className='sequence-number'>{index + 1}</span>
                {getPhasesIcon(index)}
              </div>

              <div>
                <div className='status-name-desc step-name'>
                  {style === 'complete' && (
                    <CheckCircleIcon className='check-circle' />
                  )}
                  <h2 className='content-title'>{seq.name}</h2>
                </div>
                <div className='status-name-subtitle'>
                  <p className='left content-normal dark-grey'>
                    {seq.subTitle}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SequencesPhases;
